<template>
  <div class="subscribe-div__container">
    <div v-if="!loading" class="subscribe-content">
      <div class="subscribe-title">{{$t('subscribe.pakage_plan')}}</div>
      <div class="seprate-line"></div>
      <div class="subscribe-label subscribe-div__planType">
        <div class="subscribe-div__left left-set__top" style="line-height: 32px;">
          {{ order_type === 'renew' ? $t('subscribe.renewal_version') : $t('subscribe.updated_version') }}
          <sup>*</sup>
        </div>
        <div class="subscribe-div__right">
          <!-- <span
            v-for="(item, index) in plan"
            :key="index"
            :class="[{ active: item.value === currentType }, 'plan-item-btn']"
            @click="currentType = item.value"
          >
            {{ item.title }}
          </span> -->
          <el-radio-group v-model="currentType">
            <el-radio :label="item.value" v-for="(item, index) in plan" :key="index">{{ $isZh() ? item.title : item.title_en }}</el-radio>
          </el-radio-group>
          <p class="subscribe-desc">
            {{ desc }}
          </p>
          <div><router-link class="link" to="/price">{{$t('action.show_detail')}}</router-link></div>
        </div>
      </div>
      <div v-if="showTimeFlag" class="subscribe-label subscribe-div__planTime">
        <div class="subscribe-div__left left-set__top" style="line-height: 20px;">
          {{ order_type == 'renew' ? $t('subscribe.renewal_time') : $t('subscribe.updated_time') }}
          <sup>*</sup>
        </div>
        <div class="subscribe-div__right">
          <span
            v-for="(item, index) in plan[currentType].list"
            :key="index"
            :class="['plan-item-btn', { active: item.value === currentTime }]"
            @click="currentTime = item.value"
            >
            <div style="margin-top: 46px;font-size: 16px;color: var(--color-text-1);line-height: 20px;">{{ $isZh() ? item.label : item.laben_en }}</div>
            <div style="margin-top: 16px;font-size: 24px;color: var(--color-text-1);line-height: 29px;"><span style="font-size:14px;">{{ $isZh() ? '¥' : '$' }}</span>{{ item.price.toLocaleString() }}</div>
            <!-- <div style="margin-top: 5px;font-size: 12px;color: var(--color-text-3);line-height:17px;">{{$t('subscribe.orginal_price', [item.originPrice ? item.originPrice : item.price])}}</div>
            <div v-if="item.originPrice"><span style="width: 80px;height: 22px;background-color: rgba(0,171,122,0.1);border-radius: 14px;color: #00ab7a;padding:2px 7px;font-size: 12px;">{{$t('subscribe.save', [item.originPrice - item.price])}}</span></div> -->
          </span>
        </div>
      </div>
      <div class="subscribe-label subscribe-div__durtion" v-if="order_type === 'upgrade'" style="margin-bottom: 12px">
        <div class="subscribe-div__left">
          {{$t('subscribe.updated_time')}}
        </div>
        <div class="subscribe-div__right">
          <span
            :class="['plan-item-btn', 'active']"
            >
            <div style="margin-top: 46px;font-size: 16px;color: var(--color-text-1);line-height: 20px;">{{ $t('subscribe.upgrade_duration', [upgradeDuration]) }}</div>
            <div style="margin-top: 16px;font-size: 24px;color: var(--color-text-1);line-height: 29px;"><span style="font-size:14px;">{{ $isZh() ? '¥' : '$' }}</span>{{ upgradePrice.toLocaleString() }}</div>
            <!-- <div style="margin-top: 5px;font-size: 12px;color: var(--color-text-3);line-height:17px;">{{ $t('subscribe.orginal_price', [upgradeOriginal.toLocaleString()]) }}</div>
            <div v-if="upgradeDiscount"><span style="width: 80px;height: 22px;background-color: rgba(0,171,122,0.1);border-radius: 14px;color: #00ab7a;padding:2px 7px;font-size: 12px;">{{ $t('subscribe.save', [upgradeDiscount]) }}</span></div> -->
          </span>
        </div>
      </div>
      <div class="subscribe-label subscribe-div__payMoney">
        <div class="subscribe-div__left" style="line-height: 36px;font-size:14px;">{{$t('subscribe.total_price')}}</div>
        <div class="subscribe-div__right">
          <span style="font-size: 32px;color: #00ab7a;">
            <span style="font-size: 14px;color: #00ab7a;">{{ $isZh() ? '¥' : '$' }}</span>{{ upgradePrice ? upgradePrice.toLocaleString() : currentSelect.price.toLocaleString() }}
          </span>
          <!-- <span style="font-size: 14px;color: var(--color-text-1);" v-if="currentSelect.discount === true || upgradeDiscount">
            {{$t('subscribe.saved', [upgradePrice
              ? upgradeOriginal.toLocaleString()
              : currentSelect.discount === true
              ? currentSelect.originPrice.toLocaleString()
              : currentSelect.price.toLocaleString(), upgradePrice
              ? upgradePrice.toLocaleString()
              : currentSelect.originPrice - currentSelect.price])}}
          </span> -->
          <span style="color:var(--color-text-3);font-size:14px;" class="info" v-if="order_type === 'upgrade'">
            <el-tooltip
              popper-class="plan-info-tips"
              :effect="$isDark() ? 'dark' : 'light'"
              :content="$t('subscribe.upgrade_plan_info_tips')"
              placement="right"
            >
              <!-- <img src="@/assets/img/svg/info.svg" alt /> -->
              <span class="tishi-wrap">
                <svg-icon name="zhushi"></svg-icon>
              </span>
            </el-tooltip>
          </span>
          <!-- <span style="color:var(--color-text-3);font-size:14px;" class="info" v-if="order_type !== 'upgrade' && currentSelect.discount === true">
            <el-tooltip
              popper-class="plan-info-tips"
              :effect="$isDark() ? 'dark' : 'light'"
              :content="$t('subscribe.plan_info_tips')"
              placement="right"
            >
              <span class="tishi-wrap">
                <svg-icon name="zhushi"></svg-icon>
              </span>
            </el-tooltip>
          </span> -->
          <div style="font-size: 14px;color: #00ab7a;line-height: 22px;margin-top:12px;">
            <!-- <div v-if="order_type !== 'upgrade'">服务截止时间至 {{ timeRange.endTime }}</div> -->
            <div>{{$t('subscribe.duration', [timeRange.startTime,timeRange.endTime])}}</div>
          </div>
        </div>
      </div>

      <div class="subscribe-label subscribe-div__agreement">
        <!-- <div class="subscribe-div__left" style="line-height: 30px;">
          服务协议
          <sup>*</sup>
        </div> -->
        <!-- <div class="subscribe-div__right">

        </div> -->
      </div>
      <div class="subscribe-label subscribe-div__confim">
        <div class="subscribe-div__left"></div>
        <div class="subscribe-div__right">
          <el-button
            class="uploadConfig-btn"
            type="primary"
            @click="submit"
          >
            {{ order_type == 'renew' ? $t('subscribe.to_renewal') : $t('subscribe.to_updated') }}</el-button
          >
          <div style="padding: 5px 0;">
            <el-checkbox class="uploadConfig-left__checkbox" @change="changeIsChecked" v-model="isChecked">
              {{$t('subscribe.read_agree')}}
              <router-link class="link" to="/help/docs/terms">{{$t('subscribe.user_agreement')}}</router-link>
            </el-checkbox>
          </div>
          <div v-show="!isChecked && !isReadFlag" style="font-size: 12px;color: #f5222d;line-height: 16px;">
            {{$t('subscribe.user_agreement_tip')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// import cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'
import { ref, computed, onBeforeMount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getPaymentOrderAmount, getPaymentSubscribe, getPaymentOrderPrice } from '@/services/order'
import { t, isZh } from 'app/i18n'
import { message } from 'hooks/useUI'

const router = useRouter()
const route = useRoute()
const currentType = ref('')
const currentTime = ref(route.query.price_type || 'one_month')
const upgradePrice = ref('')
const upgradeDuration = ref('')
const upgradeDiscount = ref('')
const upgradeOriginal = ref('')
const isChecked = ref(false)
const showTimeFlag = ref(true)
const order_type = ref('')
const priceInfo = ref({})
const professional = ref({})
const enterprise = ref({})
const isReadFlag = ref(true)
const loading = ref(true)
const startTime = ref('')
const endTime = ref('')

const desc = computed(() => {
  return currentType.value === 'professional' ? t('subscribe.professional') : t('subscribe.enterprise')
})
const currentSelect = computed(() => {
  return plan.value[currentType.value].list ? plan.value[currentType.value].list[currentTime.value] : {
    label: '',
    duration: '1',
    laben_en: '',
    price: 0,
    unit: '',
    value: ''
  }
})
const timeRange = computed(() => {
  let startDate, endDate
  if (routeQuery.value.type === 'renew') {
    startDate = dayjs(startTime.value).format('YYYY-MM-DD')
    endDate = dayjs(startTime.value)
      .add(currentSelect.value.duration, currentSelect.value.unit)
      .format('YYYY-MM-DD')
  } else if (routeQuery.value.type === 'upgrade') {
    startDate = dayjs(startTime.value).format('YYYY-MM-DD')
    endDate = dayjs(endTime.value).format('YYYY-MM-DD')
  } else {
    startDate = dayjs(startTime.value).format('YYYY-MM-DD')
    endDate = dayjs(startTime.value)
      .add(currentSelect.value.duration, currentSelect.value.unit)
      .format('YYYY-MM-DD')
  }
  return {
    startTime: startDate,
    endTime: endDate
  }
})
const routeQuery = computed(() => {
  return route.query
})
const plan = computed(() => {
  if (routeQuery.value.type === 'renew') {
    // 续费
    const type = priceInfo.value.account_type
    return {
      [type]: type === 'professional' ? professional.value : enterprise.value
    }
  } else if (routeQuery.value.type === 'upgrade') {
    return {
      // professional: professional.value,
      enterprise: enterprise.value
    }
  } else {
    return {
      professional: professional.value,
      enterprise: enterprise.value
    }
  }
})
onBeforeMount(() => {
  getPaymentSubscribe()
    .then((res) => {
      if (res.code) {
        throw new Error('Whoops!')
      }
      priceInfo.value = res
      if (routeQuery.value.type === 'renew') {
        currentType.value = priceInfo.value.account_type
        startTime.value = priceInfo.value.service_expiration_time
      } else if (routeQuery.value.type === 'upgrade') {
        startTime.value = priceInfo.value.current_time
        endTime.value = priceInfo.value.service_expiration_time
        // currentType.value = routeQuery.value.group || 'professional'
      } else {
        startTime.value = priceInfo.value.current_time
        // currentType.value = routeQuery.value.meal_type || 'professional'
      }
      checkTypeRender()
    })
    .catch((e) => {
      console.log(e)
    })
  getPaymentOrderPrice().then((res) => {
    if (res.status === 0) {
      professional.value = res.price.professional
      enterprise.value = res.price.enterprise
    }
  })
})

function changeIsChecked(val) {
  console.log(val)
  if (!val) {
    isReadFlag.value = true
  } else {
    isReadFlag.value = false
  }
}

function checkTypeRender() {
  const { type } = route.query
  order_type.value = type
  switch (type) {
    // 续费逻辑
    case 'renew':
      showTimeFlag.value = true
      break
    case 'purchase':
      showTimeFlag.value = true
      break
    case 'upgrade':
      showTimeFlag.value = false
      getUpdatePrice()
      break
  }
  loading.value = false
}
// function price(a, b) {
//   return a / b
// }
async function getUpdatePrice() {
  await getPaymentOrderAmount().then((res) => {
    if (!res.status) {
      upgradePrice.value = res.amount
      upgradeDuration.value = res.duration
      upgradeDiscount.value = res.amount_discount
      upgradeOriginal.value = res.amount_original
    }
  })
}
function submit() {
  if (!isChecked.value) {
    // this.$store.commit('SHOWMESSAGE', {
    //   type: 'error',
    //   message: '请阅读并同意《360沙箱云用户协议》',
    //   duration: 2000,
    //   showClose: true
    // })
    isReadFlag.value = false
    return
  }
  if (!isZh()) {
    message('International payment is being prepared, so stay tuned!', 'error')
    return
  }
  // cycle: 订阅时长, label: 购买的版本名称, months: 购买的月数(以月为单位), order_type: renew/purchase/upgrade, payAmount: 实付, total: 应付, type: professional/enterprise, discountMoney: total - payAmount
  const total = upgradePrice.value
    ? upgradeOriginal.value
    : currentSelect.value.discount
      ? currentSelect.value.originPrice
      : currentSelect.value.price

  const payAmount = upgradePrice.value ? upgradePrice.value : currentSelect.value.price

  const discountMoney = total - payAmount ? 0 - (total - payAmount) : 0

  // eslint-disable-next-line camelcase
  const params = {
    startTime: timeRange.value.startTime,
    endTime: timeRange.value.endTime,
    type: currentType.value,
    cycle: upgradePrice.value ? undefined : (isZh() ? currentSelect.value.label : currentSelect.value.laben_en),
    months: currentSelect.value.duration,
    payAmount,
    discountMoney,
    total,
    duration: upgradeDuration.value || undefined,
    order_type: order_type.value,
    label: isZh() ? plan.value[currentType.value].title : plan.value[currentType.value].title_en,
    currentTime: currentTime.value
  }
  // 跳转到确定页
  router.push({
    name: 'confimSubscribe',
    // query: this.$route.query,
    query: params
  })
}
defineExpose({currentTime, currentType})
</script>
<script>
export default {
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.currentTime = from.query.currentTime || to.query.price_type || 'one_month'
      vm.currentType = from.query.type || to.query.meal_type || to.query.group || 'professional'
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
[data-theme='light'] .subscribe-div__container {
  .plan-item-btn {
    background-image: url('~@/assets/img/user-center/upgrade-btn-bg-light.svg') !important;
    &.active {
      // bg_color(color-main-highlight);
      // background-color: #00AB7A;
      background-image: url('~@/assets/img/user-center/upgrade-btn-active-bg-light.svg') !important;
    }
  }
}

.subscribe-div__container {
  width: 100%;
  // height: 100%;
  color: var(--color-text-1);
  height: calc(100vh - 116px);
  overflow-y: auto;
  padding: 16px;
  box-sizing: border-box;
  .subscribe-content {
    background: var(--detect-config-bg);
    min-height: 100%;
  }
  .subscribe-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-text-1);
  }
  .subscribe-title, .subscribe-label {
    width: 1000px;
    margin: 0 auto;
  }

  .seprate-line {
    width: 1000px;
    margin: 11px auto;
    border-top: 1px solid var(--color-border-2);
  }

  .subscribe-title {
    font-size: 16px;
    // margin-bottom: 20px;
    // margin-top: 20px;
    padding: 20px 0 10px;
  }

  .subscribe-label {
    display: flex;
    margin-bottom: 20px;
    &.subscribe-div__planType {
      margin-top: 36px;
    }

    &.subscribe-div__payMoney {
      .subscribe-div__left {
        color: var(--color-text-1);
        font-size: 16px;
      }

      .subscribe-div__right {
        color: var(--color-text-1);
        font-size: 24px;
      }
    }

    .subscribe-div__left {
      width: 127px;
      flex-shrink: 0;
      font-size: 14px;

      sup {
        color: red;
        top: 0;
      }
    }

    .subscribe-div__right {
      font-size: 14px;
      color: #ABB1C4;
      overflow: hidden;
      .uploadConfig-btn {
        // width: 120px;
        padding: 0 30px;
        height: 40px;
      }
      .link {
        color: #00AB7A;
      }

      .price-unit {
        font-size: 14px;
        margin-left: 4px;
      }

      .price-unit-permonth {
        margin-left: 14px;
        font-size: 14px;
        color: #00AB7A;
      }

      .subscribe-discount {
        color: #cf605b;
        margin-bottom: 12px;
        font-size: 12px;
      }

      .subscribe-desc {
        // width: 600px;
        line-height: 20px;
        color: var(--color-text-3);
        margin: 8px 0 12px;
        font-size: 14px;
        color: #898A8E;
      }

      :deep(.el-checkbox) {
        .el-checkbox__inner {
          // background: transparent;
          // border-color: #00AB7A;
        }
      }
      :deep(.el-radio) {
        &.is-checked {
          .el-radio__label {
            // color: #fff !important;
          }
        }
      }

      .uploadConfig-left__checkbox {
        color: var(--color-text-3);

        :deep(.el-checkbox__label) {
          color: var(--color-text-3);
        }
      }

      // .isDisabled {
      //   background: #252C52;
      //   border-radius: 4px;
      //   border: none;
      //   font-size: 14px;
      //   color: #898A8E;
      // }

      .plan-item-btn {
        float: left;
        margin-right:40px;
        margin-top: 4px;
        box-sizing: border-box;
        // border: 1px solid #00AB7A;
        width: 120px;
        height: 160px;
        line-height: 32px;
        font-size: 14px;
        cursor: pointer;
        color: var(--color-text-1);
        text-align: center;
        // border-radius: 1px 1px 1px 4px;
        background-image: url('~@/assets/img/user-center/upgrade-btn-bg.svg');
        background-size: 100% 100%;
        &.active {
          // bg_color(color-main-highlight);
          // background-color: #00AB7A;
          background-image: url('~@/assets/img/user-center/upgrade-btn-active-bg.svg');
          background-size: 100% 100%;
          color: var(--color-text-1);
        }
      }
    }
  }
}
</style>
